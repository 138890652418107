<!--
 * @Author: bug killer: liuyh
 * @Date: 2022-04-27 15:29:25
 * @LastEditors: bug killer: liuyh
 * @LastEditTime: 2022-06-09 17:01:39
 * @Description: file components
-->
<template>
  <div class="welcome">
    <img class="logo" src="./assets/image/vDraglogo.png" alt="">
    <div class="logo-title">{{APPINFO.logoTitle}}</div>
    <div class="components">
      <topBanner />
      <myGuide />
      <example />
    </div>

    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>

<script>
import Footer from './components/footer.vue'
import topBanner from './components/banner'
import example from './components/example'
import myGuide from './components/guide'
export default {
  name: 'App',
  components: {
    Footer,
    topBanner,
    myGuide,
    example
  },
  data () {
    return {}
  },
  mounted () {}
}
</script>

<style>

#app {
  font-family: "Avenir", Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  height: 100%;
  display: flex;
  position: absolute;
  top: 0px;
  left: 0px;
  overflow-x: hidden;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-thumb {
  background-color: #444242;
  border-radius: 2px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #53504f;
}

::-webkit-scrollbar-corner, ::-webkit-scrollbar-track {
  background-color: #272727;
}
.welcome {
  width: 100%;
}

.logo{
  width: 130px;
  position: absolute;
  left:60px;
  top:50px;
  z-index: 200;
}
.logo-title {
  position: absolute;
  font-family: 微软雅黑;
  color: rgb(255, 255, 255);
  height: 32px;
  line-height: 32px;
  font-size: 20px;
  left: 120px;
  top: 55px;
}

</style>
